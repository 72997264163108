import { Grid, Typography } from "@mui/material";
import React from "react";
import "./resources.css";
import { Link } from "react-router-dom";

function EventBody({ eventDetails }) {
  const num_to_month = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  return (
    <Grid
      container
      spacing={4}
      // columnGap={4}
      sx={{
        justifyContent: "center",
        alignItems: "stretch",
        marginBottom: "3rem",
      }}
    >
      <Grid item xs={12} sm={10} md={8} lg={1.8}>
        <div className="event-body-column" style={{ alignItems: "center" }}>
          <Typography className="book-font">{eventDetails.day} </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600 }}
            className="medium-font"
          >
            {eventDetails.date}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6.2}>
        <div className="event-body-column">
          <Typography className="book-font">
            {num_to_month[eventDetails.month]} {eventDetails.date} @{" "}
            {eventDetails.time}
          </Typography>
          <Link
            to={eventDetails.eventLink}
            target="_blank"
            className="event-link"
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 550, letterSpacing: "3px", color:"#141827" }}
              className="medium-font"
            >
              {eventDetails.title}
            </Typography>
          </Link>
          {eventDetails.location ? (
            <Typography sx={{ mt: 2, fontWeight: 300, fontSize: "14px", lineHeight: 1.62 }} className="book-font">
              {eventDetails.location}
            </Typography>
          ) : null}
          <Typography sx={{ mt: 3, fontWeight: 300, fontSize: "14px", lineHeight: 1.62}} className="book-font">
            {eventDetails.desc}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={4}>
        {eventDetails.image ? (
          <img
            src={eventDetails.image}
            alt="Event Image"
            className="responsive-image"
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export default EventBody;
