import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import "./myMentors.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
  FormControl,
  NativeSelect,
  Box,
  Button,
} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import Appbar from "../Header/Appbar";

function MyMentors() {
  const { user } = useAuthenticator();
  const [mentorsList, setMentorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOption, setFilterOption] = useState("1");

  useEffect(() => {
    if (user && user.attributes) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URI}/mentee/${user.attributes.email}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.length !== 0) {
            axios
              .post(`${process.env.REACT_APP_BACKEND_URI}/mentor/email`, {
                emailList: res.data,
              })
              .then((response) => {
                setMentorsList(response.data);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("There was an error to load the mentors", error);
        });
    }
  }, [user]);

  return (
    <div>
      <Appbar />
      <div className="my-mentors">
        {loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="my-mentors-body">
            {user ? (
              <div className="sidebar">
                <Sidebar />
              </div>
            ) : null}

            <>
              {!loading && mentorsList.length === 0 ? (
                <div className="no-mentors">
                  <Typography
                    variant="h5"
                    component="h1"
                    className="department-title"
                  >
                    You Currently haven't sent any mentoring request
                  </Typography>
                  <Button
                    className="card-btn"
                    color="primary"
                    variant="contained"
                    fullWidth
                    component={Link}
                    to="/schools"
                    style={{ width: "50vh" }}
                  >
                    Select Mentors
                  </Button>
                </div>
              ) : (
                <div className="my-mentors-list">
                  <div className="list-header">
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 600 }}
                    >
                      My Mentors
                    </Typography>
                    <Box sx={{ width: 100 }}>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={filterOption}
                          onChange={(e) => setFilterOption(e.target.value)}
                          inputProps={{
                            name: "mentors",
                            id: "uncontrolled-native",
                          }}
                        >
                          <option value={1}>All</option>
                          <option value={2}>Declined</option>
                          <option value={3}>Accepted</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="department-list">
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        justifyContent: {
                          xs: "center",
                          sm: "flex-start",
                          md: "flex-start",
                        },
                      }}
                    >
                      {mentorsList.filter((mentor) => {
                        if (filterOption === "2") {
                          return mentor.response === "decline";
                        } else if (filterOption === "3") {
                          return mentor.response === "accept";
                        } else {
                          return true;
                        }
                      }).length === 0 ? (
                        <div className="no-mentors" style={{ height: "30vh" }}>
                          <Typography variant="h5" className="department-title">
                            There are no mentors in this section
                          </Typography>
                        </div>
                      ) : (
                        mentorsList
                          .filter((mentor) => {
                            if (filterOption === "2") {
                              return mentor.response === "decline";
                            } else if (filterOption === "3") {
                              return mentor.response === "accept";
                            } else {
                              return true;
                            }
                          })
                          .map((mentor, index) => (
                            <Grid
                              item
                              xs={10}
                              sm={6}
                              md={6}
                              lg={4}
                              key={index}
                              sx={{ alignItems: "center" }}
                            >
                              <Card
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CardMedia
                                  component="div" // Use a div as the container for custom styling
                                  style={{
                                    height: 0,
                                    paddingTop: "100%", // 1:1 aspect ratio (adjust as needed)
                                    backgroundImage: `url(${mentor.image})`,
                                    backgroundSize: "cover",
                                  }}
                                  title={mentor.name}
                                />
                                <CardContent>
                                  <Typography
                                    variant="h5"
                                    component="h2"
                                    className="mentor-name"
                                  >
                                    {mentor.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    style={{
                                      marginBottom: "1rem",
                                      fontSize: "1.1rem",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {mentor.description[0]}
                                  </Typography>
                                  <ul>
                                    {mentor.description.map(
                                      (desc, descIndex) =>
                                        descIndex !== 0 && (
                                          <li key={descIndex}>
                                            <Typography
                                              variant="body2"
                                              component="p"
                                              style={{
                                                fontSize: "1rem",
                                                lineHeight: "1.5",
                                              }}
                                            >
                                              {desc}
                                            </Typography>
                                          </li>
                                        )
                                    )}
                                  </ul>
                                  <div className="button-container">
                                    <Button
                                      className="card-btn"
                                      color="primary"
                                      variant="contained"
                                      fullWidth
                                      component={Link}
                                      to={mentor.bioLink}
                                    >
                                      More about {mentor.name.split(/\s+/)[0]}
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                      )}
                    </Grid>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyMentors;
