import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@mui/material";
import "./response.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";

function Response() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const response = params.get("response");
  const [loading, setLoading] = useState(false);
  const id = params.get("email_id");

  const timestamp = parseInt(id.substring(0, 8), 16);
  const timestampDate = new Date(timestamp * 1000);
  timestampDate.setDate(timestampDate.getDate() + 3);
  const expirydate = timestampDate.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  });

  const currentDate = new Date();
  const today = currentDate.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  });

  const [emailSent, setEmailSent] = useState(null);

  useEffect(() => {
    if (emailSent !== null) {
      if (emailSent) {
        if (response === "accept") {
          toast.success(
            "The confirmation has been sent to the Mentee that you have accepted their mentoring request",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (response === "decline") {
          toast.success(
            "The confirmation has been sent to the Mentee that you have declined their mentoring request",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast.error(
          "There was an error in sending the email. Check if the email IDs are correct",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
    setEmailSent(null);
  }, [emailSent, response]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_BACKEND_URI}/email/${id}`, {
        response: response,
      })
      .then((res) => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.error(error);
        setEmailSent(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false regardless of success or error
      });
  };

  return (
    <div className="container">
      {expirydate < today ? (
        <>
          <Typography variant="h6" align="center">
            The link has expired
          </Typography>
        </>
      ) : (
        <>
        {loading && (
            <div className="loading-overlay">
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                style={{color: "black"}}
              >
                Sending Email
              </LoadingButton>
            </div>
          )}
          <Card className="card" align="center">
            <CardContent>
              <Typography variant="h6" align="center">
                Are you sure you want to {response} the mentoring request?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={handleSubmit}
              >
                {response}
              </Button>
            </CardContent>
          </Card>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </div>
  );
}

export default Response;
