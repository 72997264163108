import React, { useState, useEffect } from "react";
import "./schoolList.css";
import Appbar from "../Header/Appbar";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

function SchoolsList() {
  const [loading, setLoading] = useState(false);
  const { user } = useAuthenticator();
  const [schoolsList, setSchoolsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URI}/mentor/get-schools`)
      .then((res) => {
        console.log(res.data);
        setSchoolsList(res.data);
        setLoading(false);
      });
  }, [user]);

  const handleSchoolClick = (schoolName) => {
    navigate(`/${schoolName}/mentors`);
  };

  return (
    <div className="schools">
      <Appbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="resource__body">
          <div className="sidebar">{user ? <Sidebar /> : null}</div>

          <div className="schools_body">
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 600,
                mt: 3,
                mb: 3,
                alignSelf: {
                  xs: "center",
                  sm: "flex-start",
                  md: "flex-start",
                },
              }}
              className="heading"
            >
              List of Schools
            </Typography>

            <Box
              sx={{
                width: { xs: "100%", sm: "80%", md: "60%" }, // Responsive widths
              }}
            >
              {/* <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            > */}
              {schoolsList.map((school, idx) => (
                // <Grid item xs={6} sm={6} md={6} lg={4} key={idx}>
                //   <Card style={{ height: 200 }}>
                //     <CardActionArea onClick={() => handleSchoolClick(school)}>
                //       <CardMedia
                //         component="img"
                //         height="200"
                //         image={`https://engineering.nyu.edu/sites/default/files/styles/content_header_1024_2x/public/2023-03/wunch-campus.jpeg?h=02f35539&itok=C7TCBhmW`}
                //         alt={school}
                //         style={{ position: "relative" }}
                //       />
                //       <Box
                //         sx={{
                //           position: "absolute",
                //           top: 0,
                //           left: 0,
                //           width: "100%",
                //           height: "100%",
                //           bgcolor: "rgba(0, 0, 0, 0.5)" /* Black overlay */,
                //           display: "flex",
                //           alignItems: "center",
                //           justifyContent: "center",
                //           color: "white",
                //           textAlign: 'center',
                //           p: 2
                //         }}
                //       >
                //         <Typography variant="h6">{school}</Typography>
                //       </Box>
                //     </CardActionArea>
                //   </Card>
                // </Grid>
                <Button
                  key={idx}
                  className="resources-btn"
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => handleSchoolClick(school)}
                >
                  {school}
                </Button>
              ))}
              {/* </Grid> */}
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}

export default SchoolsList;
