import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, TextField, Typography, CircularProgress } from "@mui/material";
import "./chat.css";
import PostComponent from "./Post";
import SaveIcon from "@mui/icons-material/Save";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { LoadingButton } from "@mui/lab";
import Appbar from "../Header/Appbar";

function Chat() {
  const { user } = useAuthenticator();

  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: "", content: "" });
  const [refresh, setRefresh] = useState(true);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    if (user && user.attributes) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URI}/posts/`)
        .then((res) => {
          const postPromises = res.data.map((post) => {
            post.timestamp = new Date(post.timestamp).toLocaleDateString();
            return axios
              .get(`${process.env.REACT_APP_BACKEND_URI}/comments/${post.id}`)
              .then((res2) => {
                post.comments = res2.data;
                return post;
              });
          });
          return Promise.all(postPromises);
        })
        .then((postsWithComments) => { 
          setPosts(postsWithComments);
        })
        .catch((error) => {
          console.error("Could not load all the post", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false regardless of success or error
        });
    }
  }, [user, refresh]);

  const handlePostSubmit = async (event) => {
    event.preventDefault();
    setLoadingPost(true);
    try {
      const toISOStringWithTimezone = date => {
        const tzOffset = -date.getTimezoneOffset();
        const diff = tzOffset >= 0 ? '+' : '-';
        const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
        return date.getFullYear() +
          '-' + pad(date.getMonth() + 1) +
          '-' + pad(date.getDate()) +
          'T' + pad(date.getHours()) +
          ':' + pad(date.getMinutes()) +
          ':' + pad(date.getSeconds()) +
          diff + pad(tzOffset / 60) +
          ':' + pad(tzOffset % 60);
      };
      const formattedTimestamp = toISOStringWithTimezone(new Date())
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/posts/`,
        {
          menteeId: user.attributes.email,
          title: newPost.title,
          content: newPost.content,
          timestamp: formattedTimestamp,
        }
      );
    } catch (error) {
      console.error("Could not create post:", error);
    } finally {
      setLoadingPost(false); // Set loading to false regardless of success or error
      setRefresh(!refresh);
      setNewPost({ title: "", content: "" });
    }
  };

  return (
    <div className="chat">
      <Appbar />
      <Card className="forum">
        <Typography variant="h4" className="title1">
          Discussion Forum
        </Typography>
        {loadingPost && (
          <div className="loading-overlay">
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              style={{ color: "black" }}
            >
              Creating a post
            </LoadingButton>
          </div>
        )}
        <form onSubmit={handlePostSubmit}>
          <div className="create-post-content">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={newPost.title}
              onChange={(e) =>
                setNewPost({ ...newPost, title: e.target.value })
              }
            />
            <TextField
              label="Content"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={newPost.content}
              onChange={(e) =>
                setNewPost({ ...newPost, content: e.target.value })
              }
            />
          </div>
          <div className="create-post-button">
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: "#57068c" }}
            >
              Create Post
            </Button>
          </div>
        </form>
        <Typography variant="h4" className="title1" style={{marginTop: "20px"}}>
          Posts
        </Typography>
        {loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <>
          {posts.length === 0 ? (
            <Typography
              className="mentee-name"
              style={{
                marginTop: "2rem",
                alignSelf: "center",
                fontSize: "20px",
                fontWeight: 100,
              }}
            >
              No post yet
            </Typography>
          ) : (
            <div>
              {posts.map((post) => (
                <PostComponent key={post.id} post={post} refresh={refresh} setRefresh={setRefresh} />
              ))}
            </div>
          )}
          </>
        ) }
        
      </Card>
    </div>
  );
}

export default Chat;
