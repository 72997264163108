import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Button, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ChatIcon from "@mui/icons-material/Chat";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import NYU_logo from "../../assets/NYU_logo.png";
import "./appbar.css";
import LoginIcon from "@mui/icons-material/Login";
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

export default function Appbar() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { user } = useAuthenticator();
  const navigate = useNavigate();

  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLogin = () => {
    // Redirect the user to the login page when the button is clicked
    navigate("/login");
  };

  
  const handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  // const handleProfileMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleIconClick = (str) => {
    navigate(`/${str}`);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ width: "20rem" }}
    >
      <MenuItem onClick={() => handleIconClick("home")}>
        <IconButton size="large" color="inherit">
          <HomeIcon />
        </IconButton>
        <p>Home</p>
      </MenuItem>

      <MenuItem onClick={() => handleIconClick("mentors")}>
        <IconButton size="large" color="inherit">
          <PersonSearchIcon />
        </IconButton>
        <p>Mentors</p>
      </MenuItem>

      {user ? (
        <MenuItem onClick={() => handleIconClick("resources")}>
          <IconButton size="large" color="inherit">
            <WbIncandescentIcon />
          </IconButton>
          <p>Resources</p>
        </MenuItem>
      ) : null}

      {user ? (
        <MenuItem onClick={() => handleIconClick("myMentors")}>
          <IconButton size="large" color="inherit">
            <PersonPinIcon />
          </IconButton>
          <p>My Mentors</p>
        </MenuItem>
      ) : null}
      {user ? (
        <MenuItem onClick={() => handleIconClick("chat")}>
          <IconButton size="large" color="inherit">
            <ChatIcon />
          </IconButton>
          <p>Discussion</p>
        </MenuItem>
      ) : null}
      {user ? (
        <MenuItem onClick={() => handleIconClick("profile")}>
          <IconButton size="large" color="inherit">
            <Avatar
              className="headerOption_icon"
              src={user.attributes.picture}
              style={{
                width: "30px",
                height: "30px",
                border: "2px solid #fffff",
              }}
            />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      ) : null}
      {user ? (
        <MenuItem onClick={handleLogout}>
          <IconButton size="large" color="inherit">
            <LoginIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>
          <IconButton size="large" color="inherit">
            <LoginIcon />
          </IconButton>
          <p>Login</p>
        </MenuItem>
      )}
    </Menu>
  );


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#57068C", height: "5.2rem" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "10rem", paddingLeft: "1rem" }}>
            <img src={NYU_logo} style={{ height: "5rem" }} alt="NYU Logo"></img>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              flexDirection: "row",
              gap: "0rem",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="text"
              className="nav-button"
              onClick={() => navigate("/home")}
            >
              <Typography className="nav-items"> Home </Typography>
            </Button>

            <Button
              variant="text"
              className="nav-button"
              onClick={() => navigate("/mentors")}
            >
              <Typography className="nav-items"> Mentors </Typography>
            </Button>
            {user ? (
              <Button
                variant="text"
                className="nav-button"
                onClick={() => navigate("/resources")}
              >
                <Typography className="nav-items"> Resources </Typography>
              </Button>
            ) : null}

            {user ? (
              <Button
                variant="text"
                className="nav-button"
                onClick={() => navigate("/myMentors")}
              >
                <Typography className="nav-items"> My mentors </Typography>
              </Button>
            ) : null}
            {user ? (
              <Button
                variant="text"
                className="nav-button"
                onClick={() => navigate("/chat")}
              >
                <Typography className="nav-items"> Discussion </Typography>
              </Button>
            ) : null}
            {user ? (
              <Button
                variant="text"
                className="nav-button"
                onClick={() => navigate("/profile")}
              >
                <Typography className="nav-items"> Profile </Typography>
              </Button>
            ) : null}
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              paddingRight: "20px",
            }}
          >
            {user ? (
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                className="header-btn"
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                className="header-btn"
                onClick={handleLogin}
              >
                Login / SignUp
              </Button>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "none" }, marginLeft: "auto" }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
