import './auth.css';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
Amplify.configure(awsExports);

function AuthComponent() {

  return (
    <Authenticator variation="modal">
    </Authenticator>
  );
}

export default (AuthComponent);
