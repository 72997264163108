import React from "react";
import "./overview.css";

function Overview({ overviewTopics }) {
  return (
    <div className="overview">
      <div className="overview_top">
        <h2 className="on-this-page"> On this page </h2>
        <div>
          <ul className="overview_topics_list">
            {overviewTopics.map((topic, index) => (
              <li key={index} className="overview_topic">
                <a href={`#${topic}`}>{topic}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Overview;
