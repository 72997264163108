import React from 'react'
import "./resources.css";
import { Typography } from '@mui/material';

function MonthHeader({month, year}) {
  return (
    <div className="event-header" style={{marginBottom:"3rem"}}>
        <span><Typography component="h1" sx={{ fontWeight: 300, mr:2 }} className='book-font'>
            {month} {year}
          </Typography></span>
        <div className="line"></div>
    </div>
  )
}

export default MonthHeader