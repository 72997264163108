import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./mentors.css";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";

import { useAuthenticator } from "@aws-amplify/ui-react";

import RequestForm from "./RequestForm";
import axios from "axios";
import Appbar from "../Header/Appbar";
import { useParams } from 'react-router-dom';
import SidebarWithOverview from "../SideBarWithOverview/SidebarWithOverview";

const Mentors = () => {
  const [open, setOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [mentorList, setMentorList] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [canAddMentors, setCanAddMentors] = useState(true);
  const [overviewTopics, setOverviewTopics] = useState([]);

  const navigate = useNavigate();
  const { user } = useAuthenticator();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URI}/mentor/`).then((res) => {
      setMentorList(res.data);
      const topics = res.data.map((mentorCategory) => mentorCategory._id);
      setOverviewTopics(topics);
      setLoading(false);
    });
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  const checkCanAddMentor = async (MentorEmail) => {
    setCanAddMentors(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URI}/mentee/${user.attributes.email}`
      )
      .then((res) => {
        const mentor_list = res.data;
        if (mentor_list != null) {
          const currentDate = new Date();
          const today = currentDate.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Use 24-hour format
          });
          var currRequests = 0;
          mentor_list.forEach((req) => {
            if (
              req.email === MentorEmail &&
              (req.response === "decline" ||
                req.response === "accept" ||
                req.expiry > today)
            ) {
              setCanAddMentors(false);
            } else if (req.response === "accept") {
              currRequests += 1;
            } else if (req.response == null && req.expiry > today) {
              currRequests += 1;
            }
          });
          if (currRequests >= 2) {
            setCanAddMentors(false);
          }
        }
      });
  };

  return (
    <div>
      <Appbar />
      <div className="mentors">
        <RequestForm
          open={open}
          setOpen={setOpen}
          {...formDetails}
          canAddMentors={canAddMentors}
        />
        {loading ? (
          <CircularProgress className="loading" />
        ) : (
          <div className="mentors__body">
            <SidebarWithOverview overviewTopics={overviewTopics} />
            <div className="mentors-list">
              {mentorList.map((dept, idx) => (
                <span key={idx}>
                  <span
                    style={{
                      position: "relative",
                      visibility: "hidden",
                      top: "-6rem",
                    }}
                    id={dept._id}
                  ></span>
                  <div className="department-list" key={idx}>
                    <Typography
                      variant="h5"
                      component="h1"
                      className="department-title"
                      sx={{
                        alignSelf: {
                          xs: "center",
                          sm: "flex-start",
                          md: "flex-start",
                        }
                      }}
                    >
                      {dept._id}
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        justifyContent: {
                          xs: "center",
                          sm: "flex-start",
                          md: "flex-start",
                        },
                      }}
                    >
                      {dept.mentors.map((profile, index) => (
                        <Grid
                          item
                          xs={10}
                          sm={6}
                          md={6}
                          lg={4}
                          key={index}
                          sx={{ alignItems: "center" }}
                        >
                          <Card
                            style={{
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CardMedia
                              component="div" // Use a div as the container for custom styling
                              style={{
                                height: 0,
                                paddingTop: "100%", // 1:1 aspect ratio (adjust as needed)
                                backgroundImage: `url(${profile.image})`,
                                backgroundSize: "cover",
                              }}
                            />
                            <CardContent
                              className="card-content"
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Link
                                to={profile.bioLink}
                                target="_blank"
                              >
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  className="mentor-name"
                                >
                                  {profile.name}
                                </Typography>
                              </Link>
                              <Typography
                                variant="body2"
                                component="p"
                                style={{
                                  marginBottom: "1rem",
                                  fontSize: "1.1rem",
                                  lineHeight: "1.5",
                                }}
                              >
                                {profile.description[0]}
                              </Typography>
                              <ul>
                                {profile.description.map(
                                  (desc, descIndex) =>
                                    descIndex !== 0 && (
                                      <li key={descIndex}>
                                        <Typography
                                          variant="body2"
                                          component="p"
                                          style={{
                                            fontSize: "1rem",
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          {desc}
                                        </Typography>
                                      </li>
                                    )
                                )}
                              </ul>
                              <div className="button-container">
                                <Button
                                  className="card-btn"
                                  color="primary"
                                  variant="contained"
                                  fullWidth
                                  onClick={() => {
                                    if (user) {
                                      checkCanAddMentor(profile.email);
                                      setFormDetails({
                                        mentorNameProp: profile.name,
                                        menteeNameProp: user.attributes.name,
                                        mentorEmailProp: profile.email,
                                        menteeEmailProp: user.attributes.email,
                                        emailBodyProp: `I am ${user.attributes.name} a tenure track faculty at NYU Tandon and I would like to have you as a mentor. Please let me know if you can accept this invitation.`,
                                      });
                                      setOpen(true);
                                    } else {
                                      handleLogin();
                                    }
                                  }}
                                >
                                  Become My Mentor
                                </Button>
                                <Button
                                  className="card-btn"
                                  color="primary"
                                  variant="contained"
                                  fullWidth
                                  component={Link}
                                  to={profile.bioLink}
                                  target="_blank"
                                >
                                  More about {profile.name.split(/\s+/)[0]}
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mentors;
