import {
    createBrowserRouter,
    Navigate
  } from "react-router-dom";
// import App from "../App";
import UserType from "../components/UserType/UserType";
import AuthComponent from "../components/Auth/Auth";
import Profile from "../components/Profile/Profile";
import Mentors from "../components/Mentors/Mentors";
import Home from "../components/Home/Home";
import Response from "../components/Response/Response";
import MyMentors from "../components/MyMentors/MyMentors";
import Chat from "../components/Chat/Chat";
import Resources from "../components/Resources/Resources";
import SchoolsList from "../components/Mentors/SchoolsList";

const router = createBrowserRouter([
    {
      path: "/",
      element:<Navigate to="/home" />,
    },
    {
      path: "/home",
      element: <Home />
    },
    // {
    //   path: "/schools",
    //   element: <SchoolsList />
    // },
    {
      path: "/mentors",
      element: <Mentors />
    },    
    {
        path: "/choose",
        element: <UserType />
    },
    {
        path: "/login",
        element: <AuthComponent />
    },
    {
      path: "/myMentors",
      element: <MyMentors />
    },
    {
      path: "/chat",
      element: <Chat />
    },
    {
      path: "/profile",
      element: <Profile />
    },
    {
      path: "/response",
      element: <Response />
    },
    {
      path: "/resources",
      element: <Resources />
    }
  ]);

  export default router;