import React, {useState} from 'react';
import { Box, Typography, Button } from '@mui/material';
import './userType.css'
import { Link } from 'react-router-dom';
function UserType() {
  const [isUser, setIsUser] = useState("")
  return (
    <div>
    <Box
      sx={{
        width: '25%',
        margin: '15% auto',
        padding: '20px',
        border: '3px solid black',
        borderRadius: '17px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Login as 
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '35px',
          marginTop: '26px',
        }}
      >
        <Button 
          variant="contained" 
          color="secondary" 
          className='my-button' 
          onClick={() => setIsUser("mentee")}
          component={Link} 
          to={`/${isUser}/login`}
        >
          Mentee
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          className='my-button' 
          onClick={() => setIsUser("mentor")}
          component={Link} 
          to={`/${isUser}/login`}
        >
          Mentor
        </Button>
      </Box>
    </Box>
    </div>
  );
}

export default UserType